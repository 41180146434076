/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  "use strict";
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  
		// JavaScript to be fired on all pages
		$(window).scroll(function() {
			if ($(this).scrollTop() > 1){ 
				$('.header-menu').addClass("header-fixed");
				$('.banner-fade').addClass("banner-fade-fixed");
			}
			else{
				$('.header-menu').removeClass("header-fixed");
				$('.banner-fade').removeClass("banner-fade-fixed");
			}
		});
		
		// Newsletter
		$('#subForm').submit(function (e) {
            e.preventDefault();
            $.getJSON(
            this.action + "?callback=?",
            $(this).serialize(),
            function (data) {
                if (data.Status === 400) {
                    //alert("Error: " + data.Message);
				   var errName = $("#errormessage .message"); //get element by ID
				   errName.append(data.Message); //append information to #name
				   errName.attr("class", "alert-error"); //add a class to the element
   
                } else { // 200
                   // alert("Success: " + data.Message);
					var successName = $("#errormessage .message"); //get element by ID
				   successName.append(data.Message); //append information to #name
				   successName.attr("class", "alert"); //add a class to the element
                }
            });
        });
		
		// Smooth scroll 
		$('a[href*=#]:not([href=#])').click(function() {
			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
			  var target = $(this.hash);
			  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			  if (target.length) {
				$('html,body').animate({
				  scrollTop: target.offset().top - 65
				}, 1000);
				return false;
			  }
			}
		});
		  
		// Page slide menu
		var menuwidth  = 249; // pixel value for sliding menu width
		var menuspeed  = 300; // milliseconds for sliding menu animation time
		
		var $bdy       = $('body');
		var $container = $('#content');
		var $burger    = $('#slideoutpanel');
		var negwidth   = "-"+menuwidth+"px";
		var poswidth   = menuwidth+"px";
		
		function jsAnimateMenu(tog) {
		  if(tog === 'open') {
			$bdy.addClass('openmenu');
			
			$container.animate({marginRight: negwidth, marginLeft: poswidth}, menuspeed);
			$burger.animate({width: poswidth}, menuspeed);
			$('.overlay').animate({left: poswidth}, menuspeed);
		  }
		  
		  if(tog === 'close') {
			$bdy.removeClass('openmenu');
			
			$container.animate({marginRight: 0, marginLeft: 0}, menuspeed);
			$burger.animate({width: "0"}, menuspeed);
			$('.overlay').animate({left: "0"}, menuspeed);
		  }
		}
		
		$('.menubtn').on('click',function(){
		  if($bdy.hasClass('openmenu')) {
			jsAnimateMenu('close');
		  } else {
			jsAnimateMenu('open');
		  }
		});
		
		$('main, .owl-carousel, .close-menu').on('click', function(){
			if($bdy.hasClass('openmenu')) {
				jsAnimateMenu('close');
			}
		});
		
		// Accordion 
		$('.accordion').each(function () {
			var $accordian = $(this);
			$accordian.find('.accordion__head').on('click', function () {
				$('.accordion__head').removeClass('open').addClass('close');
				$accordian.find('.accordion__content').slideUp();
				if (!$(this).next().is(':visible')) {
					$(this).removeClass('close').addClass('open');
					$(this).next().slideDown();
				}
			});
		});
		
		// Main navigation slidedown submenu
		$('.panel-container ul.sub-menu').closest('li').addClass('drop');
		
		// Left Sidebar Main Navigation
		var menu_ul = $('.panel-container .menu > li.drop > ul'),
			menu_a  = $('.panel-container .menu > li.drop > a');
		
		menu_ul.hide();
		menu_a.click(function(e) {
			e.preventDefault();
			if(!$(this).hasClass('active')) {
				menu_a.removeClass('active');
				menu_ul.filter(':visible').slideUp('normal');
				//menu_li.addClass('active-submenu');
				$(this).addClass('active').next().stop(true,true).slideDown('normal');
			} else {
				$(this).removeClass('active');
				$(this).next().stop(true,true).slideUp('normal');
			}
		});
		$('.panel-container .current-menu-parent').find('.panel-container .sub-menu').slideToggle('normal');
		
		// OWL Carousels
		$(".home-slider").owlCarousel({
			loop:true,
			margin:0,
			navigation:true,
			items : 1, //10 items above 1000px browser width
			itemsDesktop : [1000,1], //5 items between 1000px and 901px
			itemsDesktopSmall : [900,1], // betweem 900px and 601px
			itemsTablet: [600,1], //2 items between 600 and 0
			autoPlay:true,
			autoplayTimeout:6000
		});
		
		$(".sidebar").owlCarousel({
			loop:true,
			margin:0,
			navigation:true,
			dots: false,
			items : 1, //10 items above 1000px browser width
			itemsDesktop : [1000,1], //5 items between 1000px and 901px
			itemsDesktopSmall : [900,1], // betweem 900px and 601px
			itemsTablet: [600,1], //2 items between 600 and 0
			autoPlay:true,
			autoplayTimeout:6000
		});
		
		$(".logo-slider").owlCarousel({
			items : 4, //10 items above 1000px browser width
			itemsDesktop : [1000,5], //5 items between 1000px and 901px
			itemsDesktopSmall : [900,4], // betweem 900px and 601px
			itemsTablet: [600,2], //2 items between 600 and 0
			itemsMobile : false,
			pagination: false,
			center:true,
			autoPlay:true,
			autoplayTimeout:6000
		});
		
		
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
	  
	  
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		
		// Industry Tabs
		$("div.tab-menu > div.list-group > a").click(function(e) {
			e.preventDefault();
			$(this).siblings('a.active').removeClass("active");
			$(this).addClass("active");
			var index = $(this).index();
			$("div.tab>div.tab-content").removeClass("active");
			$("div.tab>div.tab-content").eq(index).addClass("active");
		});
			
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
